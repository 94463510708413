<template>
  <div>
    <div class="row">
      <div class="col-md-12">
       <filter-distributor class="mr-2 mt-2" v-model:value="distributors"></filter-distributor>
       <filter-brands class="mr-2 mt-2" v-model:value="brands" v-model:brand="brands" />
           <filter-products class=" mt-2" v-model:value="products" v-model:product="products" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
       <a-month-picker
              v-model:value="start_date"
              placeholder="Dari Tanggal"
              style="width: 300px"
              class="mr-2 mt-2"
            />
       <a-month-picker
              v-model:value="end_date"
              placeholder="Sampai Tanggal"
              style="width: 300px"
                class="mr-2 mt-2"
            />
     <a-input-search
                class="mr-2 mt-2"
                v-model:value="q"
                placeholder="Cari ..."
                style="width: 300px"
              />
      <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
            <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
            </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
        <div class="table-responsive">
      <a-table :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
         bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template
          #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        >
          <div class="custom-filter-dropdown">
            <a-input
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm)"
              >Search</a-button
            >
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
              >Reset</a-button
            >
          </div>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text === 'Aktif'
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text }}</span
          >
        </template>
        <template #action>
          <span>
            <a-tooltip title="Lihat Pengguna">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <i class="fe fe-eye" />
              </a>
            </a-tooltip>
            <a-tooltip title="Ubah Pengguna">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Detail Regional dan Area">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-globe" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </a-table>
    </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import adata from './data.json'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterBrands from '@/components/filter/FilterBrand'
const acolumns = [
  {
    title: 'NO',
    dataIndex: 'no',
    slots: { customRender: 'no' },
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: 'Bulan',
    dataIndex: 'bulan',
    slots: { customRender: 'bulan' },
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'koded_istributor',
    slots: { customRender: 'kodedistributor' },
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'nama_distributor',
    sorter: (a, b) => a.customer.length - b.customer.length,
    slots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customer',
    },
    onFilter: (value, record) => record.customer.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: 'Produk',
    dataIndex: 'produk',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Stok Awal',
    dataIndex: 'stok_awal',
  },
  {
    title: 'Sell In',
    dataIndex: 'sell_in',
  },
  {
    title: 'Sell Out',
    dataIndex: 'sell_out',
  },
  {
    title: 'Penjualan Dengan ID Toko',
    dataIndex: 'penjualan_dengan_id_bk',
  },
   {
    title: 'Penjualan Tanpa ID Toko',
    dataIndex: 'penjualan_tanpa_id_bk',
  },
   {
    title: 'Selisih Stock',
    dataIndex: 'selisih_stock',
  },
   {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.length - b.status.length,
    slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default {
  components: {
    // MModal,
    FilterProducts,
    FilterBrands,
    FilterDistributor,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)
    data.value = adata.slice(1, 5)
    columns.value = acolumns

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    return {
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
